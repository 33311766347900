interface Coin {
  proName: string;
  title: string;
}

export const coinList: Coin[] = [
  {
    proName: "BINANCE:BTCUSDT",
    title: "BTC",
  },
  {
    proName: "BINANCE:ETHUSDT",
    title: "ETH",
  },
  {
    proName: "BINANCE:SOLUSDT",
    title: "SOL",
  },
  {
    proName: "BINANCE:BNBUSDT",
    title: "BNB",
  },
  {
    proName: "BINANCE:AVAXUSDT",
    title: "AVAX",
  },
  {
    proName: "BINANCE:ADAUSDT",
    title: "ADA",
  },
  {
    proName: "BINANCE:DOTUSDT",
    title: "DOT",
  },
  {
    proName: "BINANCE:DOGEUSDT",
    title: "DOGE",
  },
];
