export interface IExchange {
  id: number;
  name: string;
}

export interface ICurrency {
  id: number;
  name: string;
}

export interface DrawdownLimit {
  first_st: number;
  second_st: number;
  third_st: number;
}

interface Balance {
  [currency: string]: number;
}

export interface BotFormValues {
  name: string;
  trading_mode: string;
  candle_type: string;
  exchange: number[];
  currency: number[];
  data_collector_config: number;
  risk_management_strategy_config: number;
  balance: Balance;
  signal_strategy_v2: number;
  minimum_buy_order_size: number;
  minimum_sell_order_size: number;
  drawdown_limit: DrawdownLimit;
  sell_type: string;
}

export interface Signal {
  left_type: string;
  left_value: number | string;
  left_params: Record<string, any>;
  right_type: string;
  right_value: number | string;
  right_params: Record<string, any>;
  middle_value: string;
  signal_type: string;
}

export type TabItem = {
  label: string;
  content: JSX.Element;
};

export type SkeletonItem = {
  type?: "rectangular" | "circular" | "text";
  width?: string | number;
  height?: number | string;
  className?: string;
};

export enum ModalComponentTypeEnum {
  "CONFIRMATION_MODAL",
}

export type ModalMaxWidthType = "xs" | "sm" | "md" | "lg" | "xl";
