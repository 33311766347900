import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { helpDeskFormSchema } from "./form-schema";
import { HelpDeskFormDataType } from "./types";

import { useHelpDeskMutation } from "@/redux/api/authApi";
import AppButton from "@/components/Button/AppButton";
import { Logo } from "@/components/svg";

const HelpDesk = () => {
  const [helpDeskMutation] = useHelpDeskMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<HelpDeskFormDataType>({
    resolver: yupResolver(helpDeskFormSchema),
    defaultValues: {
      email: "",
      message: "",
    },
  });

  const onSubmit = ({ email, message }: HelpDeskFormDataType) => {
    helpDeskMutation({ email, message }).then((data: any) => {
      toast.info(data.data.message);
      reset();
    });
  };

  return (
    <div className="w-full min-h-screen h-full">
      <div className="w-full flex justify-between items-center py-10 px-[70px] ">
        <Logo width={134} />
      </div>
      <div className="flex w-full min-h-[calc(100vh-69px)]">
        <div className="flex-1 flex py-6 px-[70px] flex-col gap-4">
          <h2 className="font-medium text-3xl text-primary-50">Help Desk</h2>
          <p className="font-normal text-[18px] max-w-[410px] text-primary-50">
            Your satisfaction is priority here. Whether you need help with
            trading, have questions, or want to share your ideas, our team is
            happy to provide you with an exceptional user experience.
          </p>
          <div className="flex flex-col">
            <p className="text-[18px] font-normal text-primary-50">
              Want more about Botico?
            </p>
            <p>
              Go to{" "}
              <a
                href="https://botico.app"
                className="text-[18px] font-medium text-secondary-500"
              >
                Botico
              </a>
            </p>
          </div>

          <div className="flex flex-col">
            <p className="text-[18px] font-normal text-primary-50">
              Want to take a course?
            </p>
            <p>
              Go to{" "}
              <a
                href="https://botico.academy"
                className="text-[18px] font-medium text-secondary-500"
              >
                Botico Academy
              </a>
            </p>
          </div>
        </div>
        <div className="flex-1 p-6">
          <div className="max-w-xl flex flex-col gap-4 p-5 bg-primary-800 border border-primary-dark-500 rounded-3xl">
            <div className="flex flex-col gap-4">
              <h4 className="text-xl font-bold">Send us your request</h4>
              <p className="text-primary-50 font-normal text-[18px]">
                Request access to our Beta version and join a select group of
                users. Your feedback is not just valued—it’s vital.
              </p>
            </div>
            <form
              className="flex flex-col gap-7"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col gap-1">
                <p className="text-base font-medium text-white">
                  Username or Email
                </p>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      placeholder="Username or Email"
                      size="small"
                      error={!!errors.email}
                      helperText={errors.email && errors.email.message}
                    />
                  )}
                />
              </div>

              <div className="flex flex-col gap-1 mb-6">
                <p className="text-base font-medium text-white">Your message</p>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      multiline
                      rows={4}
                      size="small"
                      placeholder="Your message..."
                    />
                  )}
                />
              </div>

              <AppButton type="submit" variant="primary" fullWidth>
                Submit
              </AppButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpDesk;
