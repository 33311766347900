import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "./../../constants";
import { axiosBaseQuery, providesTags } from "./baseQuery";

const tag = "Backtesting";

export const backTestingApi = createApi({
  reducerPath: "backTestingApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getBackTestingInstance: builder.query<any, void>({
      query: () => ({ url: "/backtesting/instance/config", method: "GET" }),
      providesTags: providesTags(tag),
    }),
    getBackTestingInstanceById: builder.query<any, string>({
      query: (id) => ({
        url: `/backtesting/instance/config/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: tag, id }],
    }),
    addBackTestingInstance: builder.mutation<any, Partial<any>>({
      query: (data) => ({
        url: "/backtesting/instance/config",
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    deleteBackTestingInstance: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `/backtesting/instance/config/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
    deleteBackTestingInstanceByIdList: builder.mutation<
      { success: boolean },
      number[]
    >({
      query: (idList) => ({
        url: "/backtesting/instance/config",
        method: "DELETE",
        data: { id_list: idList },
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    playBackTestingInstance: builder.mutation<any, number>({
      query: (id) => ({
        url: `/backtesting/instance/run/${id}`,
        method: "POST",
        data: { backtesting_status: true },
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    getBackTestingResult: builder.query<any, number>({
      query: (id) => ({
        url: `/backtesting/instance/reports/${id}`,
        method: "GET",
      }),
    }),
    getBackTestingChartData: builder.query<any, { id: number; name: string }>({
      query: ({ id, name }) => ({
        url: `/backtesting/instance/reports/plot/${name}/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetBackTestingInstanceQuery,
  useGetBackTestingInstanceByIdQuery,
  useAddBackTestingInstanceMutation,
  useDeleteBackTestingInstanceMutation,
  useDeleteBackTestingInstanceByIdListMutation,
  usePlayBackTestingInstanceMutation,
  useLazyGetBackTestingResultQuery,
  useLazyGetBackTestingChartDataQuery,
} = backTestingApi;
