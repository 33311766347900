import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "./../../constants";
import { axiosBaseQuery, providesTags } from "./baseQuery";

const tag = "BotInstance";

export const botInstanceApi = createApi({
  reducerPath: "botInstanceApi",
  tagTypes: [tag],
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getBotInstance: builder.query<any, void>({
      query: () => ({ url: "/bot/instance/config", method: "GET" }),
      providesTags: providesTags(tag),
    }),
    getSingleBotInstance: builder.query<any, string>({
      query: (id: string) => ({
        url: `/bot/instance/config/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: tag, id }],
    }),
    deleteBot: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `/bot/instance/config/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
    createBotInstance: builder.mutation<any, Partial<any>>({
      query: (data) => ({
        url: "/bot/instance/config",
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    runBot: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/bot/instance/run/${id}`,
        method: "POST",
        data: { bot_status: true },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: tag, id }],
    }),
    stopBot: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/bot/instance/run/${id}`,
        method: "POST",
        data: { bot_status: false },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: tag, id }],
    }),
    cancelBuyAllOrders: builder.mutation<void, number>({
      query: (id) => ({
        url: `/bot/instance/orders/buy/cancel/${id}`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
    cancelSellAllOrders: builder.mutation<void, number>({
      query: (id) => ({
        url: `/bot/instance/orders/sell/cancel/${id}`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
  }),
});

export const {
  useGetBotInstanceQuery,
  useGetSingleBotInstanceQuery,
  useDeleteBotMutation,
  useStopBotMutation,
  useRunBotMutation,
  useCancelBuyAllOrdersMutation,
  useCancelSellAllOrdersMutation,
  useCreateBotInstanceMutation,
} = botInstanceApi;
