import { Dialog, DialogTitle } from "@mui/material";
import { useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ModalComponentTypeEnum } from "../../types/types";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

import { closeModal } from "./modal.slice";

const Modal = () => {
  const dispatch = useAppDispatch();
  const { isOpen, title, componentType, componentProps, maxWidth } =
    useAppSelector((state) => state.modal);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const content = useMemo(() => {
    switch (componentType) {
      case ModalComponentTypeEnum.CONFIRMATION_MODAL:
        return <ConfirmationModal {...componentProps} />;

      default:
        return null;
    }
  }, [componentType, componentProps]);

  return (
    <Dialog
      fullWidth
      scroll="paper"
      open={isOpen}
      onClose={handleClose}
      maxWidth={maxWidth}
      className="rounded-xl"
    >
      {title && <DialogTitle className="text-white">{title}</DialogTitle>}
      {content}
    </Dialog>
  );
};

export default Modal;
