import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "./../../constants";
import { axiosBaseQuery } from "./baseQuery";
import { providesTags } from "./baseQuery";

const tag = "RiskConfig";

export const riskConfigApi = createApi({
  reducerPath: "riskConfig",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getRiskConfig: builder.query<any, void>({
      query: () => ({ url: "/riskmanagement/config", method: "GET" }),
      transformResponse: (response: any) => response.reverse(),
      providesTags: providesTags(tag),
    }),
    getRiskConfigById: builder.query<any, number>({
      query: (id) => ({ url: `/riskmanagement/config/${id}`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: tag, id }],
    }),
    getDefaultRiskConfig: builder.query<any, void>({
      query: () => ({ url: "/riskmanagement/defaults", method: "GET" }),
    }),
    addRiskConfig: builder.mutation<any, Partial<any>>({
      query: (data) => ({
        url: "/riskmanagement/config",
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    editRiskConfig: builder.mutation<any, { id: number; data: Partial<any> }>({
      query: ({ id, data }) => ({
        url: `/riskmanagement/config/${id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: tag, id }],
    }),
    deleteRiskConfig: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `/riskmanagement/config/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
  }),
});

export const {
  useGetRiskConfigQuery,
  useGetRiskConfigByIdQuery,
  useGetDefaultRiskConfigQuery,
  useAddRiskConfigMutation,
  useEditRiskConfigMutation,
  useDeleteRiskConfigMutation,
} = riskConfigApi;
