import { combineReducers } from "redux";

import modalReducer from "../components/Modal/modal.slice";

import { collectorApi } from "./api/collectorApi";
import { statisticsApi } from "./api/statisticsApi";
import { riskConfigApi } from "./api/riskConfigApi";
import { accountApi } from "./api/accountApi";
import { authApi } from "./api/authApi";
import { botInstanceApi } from "./api/botInstanceApi";
import { exchangeApi } from "./api/exchangeApi";
import { currencyApi } from "./api/currencyApi";
import { signalLegoApi } from "./api/signalLegoApi";
import { adminApi } from "./api/adminApi";
import { buyOrdersApi } from "./api/buyOrdersApi";
import { sellOrdersApi } from "./api/sellOrderApi";
import { reportApi } from "./api/reportApi";
import { backTestingApi } from "./api/backtestingApi";
import { backTestCOllectorApi } from "./api/backTestCollectorApi";
import { externalApi } from "./api/externalApi";
import { coinsApi } from "./api/coinsApi";
import { openOrdersApi } from "./api/openOrdersApi";

const appReducer = combineReducers({
  modal: modalReducer,

  [authApi.reducerPath]: authApi.reducer,
  [botInstanceApi.reducerPath]: botInstanceApi.reducer,
  [collectorApi.reducerPath]: collectorApi.reducer,
  [statisticsApi.reducerPath]: statisticsApi.reducer,
  [riskConfigApi.reducerPath]: riskConfigApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [exchangeApi.reducerPath]: exchangeApi.reducer,
  [currencyApi.reducerPath]: currencyApi.reducer,
  [signalLegoApi.reducerPath]: signalLegoApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [buyOrdersApi.reducerPath]: buyOrdersApi.reducer,
  [sellOrdersApi.reducerPath]: sellOrdersApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [backTestingApi.reducerPath]: backTestingApi.reducer,
  [backTestCOllectorApi.reducerPath]: backTestCOllectorApi.reducer,
  [externalApi.reducerPath]: externalApi.reducer,
  [coinsApi.reducerPath]: coinsApi.reducer,
  [openOrdersApi.reducerPath]: openOrdersApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "authApi/logout/fulfilled") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
