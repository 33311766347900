import * as yup from "yup";

export const validationLoginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email('Email must contain an "@" symbol and a valid domain')
    .max(255, "Email cannot be longer than 255 characters"),
  password: yup.string().required("Password is required"),
});
