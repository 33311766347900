import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "../../constants";

import { axiosBaseQuery } from "./baseQuery";
import { providesTags } from "./baseQuery";

interface Coin {
  id: number;
  name: string;
}

const tag = "Coins";

export const coinsApi = createApi({
  reducerPath: "coinsApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getCoins: builder.query<Coin[], void>({
      query: () => ({ url: "/coins/currency", method: "GET" }),
      transformResponse: (response: any) => response.reverse(),
      providesTags: providesTags(tag),
    }),
    addCoin: builder.mutation<any, Partial<any>>({
      query: (data) => ({
        url: "/coins/currency",
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    deleteCoin: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `/coins/currency/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
  }),
});

export const { useGetCoinsQuery, useAddCoinMutation, useDeleteCoinMutation } =
  coinsApi;
