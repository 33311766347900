import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

import {
  Dashboard,
  BotBuilder,
  TradeHistory,
  RiskStrategy,
  SignalStrategy,
  Backtesting,
  MessageQuestion,
} from "../svg";

interface SubMenuItem {
  name: string;
  path: string;
}

interface MenuItem {
  name: string;
  path: string;
  icon?: React.ReactNode;
  subMenu?: SubMenuItem[];
  disabled?: boolean;
}

export const menuItems: MenuItem[] = [
  {
    name: "routers.dashboard",
    path: "/dashboard",
    icon: <Dashboard />,
  },
  {
    name: "common.bot-builder",
    path: "/bot-lab",
    icon: <BotBuilder />,
  },
  {
    name: "routers.orders",
    path: "/trade-history",
    icon: <TradeHistory />,
  },
  {
    name: "pages.risk-management.title",
    path: "/risk-management",
    icon: <RiskStrategy />,
  },
  {
    name: "pages.signal-strategy.title",
    path: "/signal-strategy",
    icon: <SignalStrategy />,
  },
  {
    name: "pages.backtesting.title",
    path: "",
    icon: <Backtesting />,
    subMenu: [
      { name: "Simulation", path: "/back-testing" },
      { name: "Backtest Periods", path: "/back-testing/collector" },
    ],
  },
  {
    name: "pages.reporting.title",
    path: "/reporting",
    icon: <AssessmentOutlinedIcon sx={{ color: "#6C767E" }} />,
    disabled: true,
  },
  {
    name: "Help Desk",
    path: "/help-desk",
    icon: <MessageQuestion stroke="#6C767E" />,
  },
];
