import * as React from "react";
import { Router } from "react-router-dom";

const BrowserRouter = ({ history, children, ...rest }) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...rest}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
};

export default BrowserRouter;
