import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="#F9F9FF"
        d="M18.5 9.578h-13a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1Z"
      />
      <mask id="b" fill="#fff">
        <path d="M10 16.578a1 1 0 1 0 0 2v-2Zm4 2a1 1 0 1 0 0-2v2Zm-4 0h4v-2h-4v2Z" />
      </mask>
      <path
        fill="#fff"
        d="M10 16.578a1 1 0 1 0 0 2v-2Zm4 2a1 1 0 1 0 0-2v2Zm-4 0h4v-2h-4v2Z"
      />
      <path
        fill="#F9F9FF"
        d="M18.333 18.578v-2H1.667v2h16.666Zm-12.666-2v2h16.666v-2H5.667ZM10-.09c-9.757 0-17.667 7.91-17.667 17.667h33.334c0 8.652-7.015 15.666-15.667 15.666V-.089ZM-7.667 17.578c0 9.757 7.91 17.666 17.667 17.666V1.911c8.652 0 15.667 7.014 15.667 15.667H-7.667ZM14 35.244c9.757 0 17.667-7.91 17.667-17.666H-1.667C-1.667 8.925 5.348 1.91 14 1.91v33.333Zm17.667-17.666C31.667 7.82 23.757-.09 14-.09v33.333c-8.652 0-15.667-7.014-15.667-15.666h33.334ZM10 35.244h4V1.911h-4v33.333ZM14-.089h-4v33.333h4V-.089Z"
        mask="url(#b)"
      />
      <path
        stroke="#F9F9FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 5.578v4M2 13.578v4M22 13.578v4"
      />
      <path stroke="#F9F9FF" d="M12 5.578a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .578h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
