import moment from "moment/moment";

import { candleSizes } from "@/components/BotBuilder/DataCollection/const";

export const changeStatusColor = (type?: string) => {
  const lowercaseType = type?.toLowerCase();
  switch (lowercaseType) {
    case "success":
      return "#7ee77e";
    case "failed":
      return "#ff4747";
    default:
      return "";
  }
};

export const copyText = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard: " + text);
    })
    .catch((error) => {
      console.error("Failed to copy text:", error);
    });
};

export const convertToDecimalNums = (num: number) => {
  return Math.round(num) / 100;
};

export const convertToPercentNums = (num: number, decimalPlaces: number) => {
  const multipliedResult = num * 100;
  return (
    Math.round(multipliedResult * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces)
  );
};

export const handleClassNameByExchange = (exchange?: string) => {
  const lowerExchange = exchange?.toLowerCase();
  switch (lowerExchange) {
    case "binance":
      return "bg-[#181a20] text-[#f0b90b] lowercase";
    case "coinbase":
      return "bg-[#0052ff] text-[#fff] lowercase";
    case "kraken":
      return "bg-[#5841d8] text-[#fff] lowercase";
    default:
      return "bg-primary-black text-[#b2b2b2]";
  }
};

const regex = /[0-9]+_(\w+)/;

export const removeNumbersAndUnderscores = (str: string) => {
  const match = str.match(regex);
  return match ? match[1] : "";
};

export const convertToUTC = (date, isChecked?: boolean) => {
  if (date) {
    return isChecked
      ? moment.unix(date).utc().format("YYYY-MM-DD HH:mm:ss")
      : moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
  }
  return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
};

export const getCandleLabel = (value: number | string) => {
  const candleSize = candleSizes.find((candle) => candle.value === value);
  return candleSize ? candleSize.label : null;
};

export const getCandleMin = (value: number) => {
  const candleSize = candleSizes.find((candle) => candle.value === value);
  if (candleSize) {
    const labelWithoutUnit = candleSize.label.replace(/\D/g, "");
    const multiplier = candleSize.label.includes("h")
      ? 60
      : candleSize.label.includes("d")
        ? 24
        : 1;
    return parseInt(labelWithoutUnit) * multiplier;
  } else {
    return null;
  }
};

export const generateCSVFile = (csv: any) => {
  const decodedData = atob(decodeURIComponent(csv));
  const blob = new Blob([decodedData], { type: "text/csv" });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = "report.csv";
  link.click();
  window.URL.revokeObjectURL(url);
};

export const formatBotDuration = (startTimeUnix: number) => {
  const currentDate = moment().format("YYYY-MM-DD HH:mm");
  const startTime = moment.unix(startTimeUnix).format("YYYY-MM-DD HH:mm");
  const endTime = moment(currentDate, "YYYY-MM-DD HH:mm");
  const duration = moment.duration(endTime.diff(startTime));

  let durationFormatted;
  if (duration.asSeconds() >= 86400) {
    const days = Math.floor(duration.asDays());
    const remainingDuration = moment.duration(
      duration.asMilliseconds() - days * 86400000,
    );
    const hours = remainingDuration.hours();
    const minutes = remainingDuration.minutes();
    durationFormatted = `${days}d ${hours}h ${minutes}m`;
  } else if (duration.asSeconds() >= 3600) {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    durationFormatted = `${hours}h ${minutes}m`;
  } else {
    durationFormatted = duration.minutes() + "m";
  }

  return durationFormatted;
};
