import { Link } from "react-router-dom";

import LoginForm from "./LoginForm";
import styles from "./login.module.scss";
import LoginWrapper from "./LoginWrapper";

import { MessageQuestion } from "@/components/svg";

const Login: React.FC = () => {
  return (
    <div className={styles.login}>
      <LoginWrapper />
      <div className={styles.loginFormWrapper}>
        <div className="w-full flex justify-end">
          <Link to={"/help-desk"} className="flex items-center gap-2">
            <MessageQuestion />
            <h4 className="font-bold text-base">Help Desk</h4>
          </Link>
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
