import { useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { useRegistrationMutation } from "../../redux/api/authApi";
import AppButton from "../../components/Button/AppButton";
import history from "../../history";
import StyleAlert from "../../components/Alert/StyleAlert";

import { validationRegisterSchema } from "./validationSchema";
import styles from "./../Login/login.module.scss";

interface IFormInput {
  firstname: string;
  lastname: string;
  middlename?: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
}

const RegisterForm: React.FC = () => {
  const { t } = useTranslation();
  const [registration] = useRegistrationMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    numberOrSymbol: false,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>({
    resolver: yupResolver(validationRegisterSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      middlename: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      terms: false,
    },
  });

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    setPasswordValidation({
      length: value.length >= 8,
      lowercase: /[a-z]/.test(value),
      uppercase: /[A-Z]/.test(value),
      numberOrSymbol: /[0-9!@#$%^&*]/.test(value),
    });
  };

  const PasswordValidation = () => (
    <div className={styles.passwordValidation}>
      <p style={{ color: passwordValidation.length ? "#08A858" : "#CE1C06" }}>
        {passwordValidation.length ? <DoneIcon /> : <CloseIcon />} At least 8
        characters
      </p>
      <p
        style={{ color: passwordValidation.lowercase ? "#08A858" : "#CE1C06" }}
      >
        {passwordValidation.lowercase ? <DoneIcon /> : <CloseIcon />} At least
        one lowercase letter
      </p>
      <p
        style={{ color: passwordValidation.uppercase ? "#08A858" : "#CE1C06" }}
      >
        {passwordValidation.uppercase ? <DoneIcon /> : <CloseIcon />} At least
        one uppercase letter
      </p>
      <p
        style={{
          color: passwordValidation.numberOrSymbol ? "#08A858" : "#CE1C06",
        }}
      >
        {passwordValidation.numberOrSymbol ? <DoneIcon /> : <CloseIcon />} At
        least one number or symbol
      </p>
    </div>
  );

  const allValid = Object.values(passwordValidation).every(Boolean);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const { confirmPassword, ...formData } = data;

    try {
      const result = await registration(formData).unwrap();
      if (result) {
        reset();
        setIsAlert(true);
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };

  return (
    <div className={styles.loginFormContainer}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.loginForm}>
        <h2 className={styles.title}>Sign up</h2>

        {isAlert && (
          <StyleAlert
            text={
              "Thank you for registering! Your email is under review by the moderator. Once it’s approved, you will receive a verification email."
            }
            type="info"
            icon={<CheckCircleOutlineIcon />}
          />
        )}

        <Controller
          name="firstname"
          control={control}
          render={({ field }) => (
            <div className="mb-3 flex flex-col w-full">
              <InputLabel className="mb-1 text-white">
                {t("pages.login.form.firstname")}
              </InputLabel>
              <TextField
                {...field}
                className="wfull"
                autoComplete="off"
                error={!!errors.firstname}
                helperText={errors.firstname ? errors.firstname.message : ""}
                slotProps={{
                  htmlInput: {
                    className: "py-3 rounded-md",
                  },
                }}
              />
            </div>
          )}
        />
        <Controller
          name="lastname"
          control={control}
          render={({ field }) => (
            <div className="mb-3 flex flex-col w-full">
              <InputLabel className="mb-1 text-white">
                {t("pages.login.form.lastname")}
              </InputLabel>
              <TextField
                {...field}
                className="wfull"
                autoComplete="off"
                error={!!errors.lastname}
                helperText={errors.lastname ? errors.lastname.message : ""}
                slotProps={{
                  htmlInput: {
                    className: "py-3 rounded-md",
                  },
                }}
              />
            </div>
          )}
        />
        <Controller
          name="middlename"
          control={control}
          render={({ field }) => (
            <div className="mb-3 flex flex-col w-full">
              <InputLabel className="mb-1 text-white">
                {t("pages.login.form.middlename")}
              </InputLabel>
              <TextField
                {...field}
                className="wfull"
                autoComplete="off"
                error={!!errors.middlename}
                helperText={errors.middlename ? errors.middlename.message : ""}
                slotProps={{
                  htmlInput: {
                    className: "py-3 rounded-md",
                  },
                }}
              />
            </div>
          )}
        />
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <div className="mb-3 flex flex-col w-full">
              <InputLabel className="mb-1 text-white">
                {t("pages.login.form.username")}
              </InputLabel>
              <TextField
                {...field}
                className="wfull"
                autoComplete="off"
                error={!!errors.username}
                helperText={errors.username ? errors.username.message : ""}
                slotProps={{
                  htmlInput: {
                    className: "py-3 rounded-md",
                  },
                }}
              />
            </div>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <div className="mb-3 flex flex-col w-full">
              <InputLabel className="mb-1 text-white">
                {t("pages.login.form.email")}
              </InputLabel>
              <TextField
                {...field}
                className="wfull"
                autoComplete="off"
                label={t("pages.login.form.email")}
                slotProps={{
                  input: {
                    endAdornment: <MailOutlineIcon />,
                  },
                  htmlInput: {
                    className: "py-3 rounded-md",
                  },
                }}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            </div>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <div className="mb-3 flex flex-col w-full">
              <InputLabel className="mb-1 text-white">
                {t("pages.login.form.password")}
              </InputLabel>
              <TextField
                {...field}
                className="wfull"
                type={showPassword ? "text" : "password"}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
                onChange={(e) => {
                  field.onChange(e);
                  handlePasswordChange(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: "#A8B5BE" }}
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                slotProps={{
                  htmlInput: {
                    className: "py-3 rounded-md",
                  },
                }}
              />
            </div>
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <div className="mb-3 flex flex-col w-full">
              <InputLabel className="mb-1 text-white">
                {t("pages.login.form.confirmPassword")}
              </InputLabel>
              <TextField
                {...field}
                className="wfull"
                label={t("pages.login.form.confirmPassword")}
                type={showPassword ? "text" : "password"}
                error={!!errors.confirmPassword}
                helperText={
                  errors.confirmPassword ? errors.confirmPassword.message : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: "#A8B5BE" }}
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                slotProps={{
                  htmlInput: {
                    className: "py-3 rounded-md",
                  },
                }}
              />
            </div>
          )}
        />

        {password.length > 0 && !allValid && <PasswordValidation />}

        <Controller
          name="terms"
          control={control}
          render={({ field }) => (
            <div className={styles.terms}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value || false}
                    sx={{
                      color: "#3F48E0",
                      "&.Mui-checked": {
                        color: "#3F48E0",
                      },
                    }}
                  />
                }
                label={
                  <span className={styles.termslink}>
                    I agree to the{" "}
                    <a
                      href="https://botico.tech/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Condition
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://botico.tech/privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </span>
                }
              />
              {errors.terms && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.terms.message}
                </span>
              )}
            </div>
          )}
        />

        <AppButton type="submit" variant="primary" fullWidth>
          {t("pages.login.form.signup")}
        </AppButton>

        <div className={styles.registerText}>
          Already a member?{" "}
          <span
            className={styles.registerLink}
            onClick={() => {
              history.push("/login");
            }}
          >
            Log in
          </span>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
