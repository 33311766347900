import { ColumnDef } from "@tanstack/react-table";

import { convertToUTC } from "../../../utils";
import AppButton from "../../../components/Button/AppButton";

interface AccountData {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  middlename?: string;
  username: string;
  create_date: number;
  email_confirmation: boolean;
}

export const getAccountConfirmationColumns = (
  confirmAccount: (data: { email: string; id: number }) => Promise<any>,
): ColumnDef<AccountData>[] => [
  {
    accessorKey: "id",
    cell: (cdata) => cdata.getValue<string>(),
    header: () => <span>ID</span>,
  },
  {
    accessorKey: "email",
    cell: (cdata) => cdata.getValue<string>(),
    header: () => <span>Email</span>,
  },
  {
    accessorKey: "firstname",
    cell: (cdata) => cdata.getValue<string>(),
    header: () => <span>Firstname</span>,
  },
  {
    accessorKey: "lastname",
    cell: (cdata) => cdata.getValue<string>(),
    header: () => <span>Lastname</span>,
  },
  {
    accessorKey: "middlename",
    cell: (cdata) => cdata.getValue<string>(),
    header: () => <span>Middlename</span>,
  },
  {
    accessorKey: "username",
    cell: (cdata) => cdata.getValue<string>(),
    header: () => <span>Username</span>,
  },
  {
    accessorKey: "create_date",
    cell: (cdata) => convertToUTC(cdata.getValue<string>()),
    header: () => <span>Create Date</span>,
  },
  {
    accessorKey: "email_confirmation",
    cell: (cdata) => {
      const data = cdata.getValue<string>();
      return data ? "true" : "false";
    },
    header: () => <span>Confirmation</span>,
  },
  {
    accessorKey: "email_confirmation_sent",
    cell: (cdata) => {
      const data = cdata.getValue<string>();
      return data ? "true" : "false";
    },
    header: () => <span>Confirmation Sent</span>,
  },
  {
    accessorKey: "email_confirmation_sent_date",
    cell: (cdata) => convertToUTC(cdata.getValue<string>()),
    header: () => <span>Confirmation Date</span>,
  },
  {
    accessorKey: "action",
    cell: (cdata) => {
      const rowData = cdata.row.original;

      const handleApprove = () => {
        confirmAccount({ email: rowData.email, id: rowData.id })
          .then(() => {})
          .catch((error) => {
            console.error("Failed to approve account:", error);
            alert("Failed to approve account.");
          });
      };
      return (
        <AppButton variant="primary" type="button" onClick={handleApprove}>
          Approve
        </AppButton>
      );
    },
    header: () => <span>Action</span>,
  },
];
