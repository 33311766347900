import React, { useMemo } from "react";
import { Tabs as MuiTabs, Tab as MuiTab, Box } from "@mui/material";

import { TabItem } from "../../types/types";

type Props = {
  tabs: TabItem[];
  selectedTab: TabItem;
  onTabChange: (selectedTab: TabItem) => void;
  headerSlot?: React.ReactNode;
};

const Tabs: React.FC<Props> = ({
  tabs,
  headerSlot,
  selectedTab,
  onTabChange,
}) => {
  const indicatorLeftPosition = useMemo(() => {
    const selectedIndex = tabs.findIndex(
      (tab) => tab.label === selectedTab?.label,
    );
    const tabWidthPercent = 100 / tabs.length;
    return `calc(${selectedIndex} * ${tabWidthPercent}% + ${
      tabWidthPercent / 2
    }% - 10px)`;
  }, [selectedTab, tabs]);

  const handleChange = (_event: React.SyntheticEvent, newValue: TabItem) => {
    onTabChange(newValue);
  };

  return (
    <Box>
      <div className="flex items-center justify-between">
        <MuiTabs
          value={selectedTab}
          onChange={handleChange}
          variant="fullWidth"
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              width: "20px",
              left: indicatorLeftPosition,
              transition: "left 0.3s ease",
              backgroundColor: "#3F48E0",
            },
          }}
          sx={{ position: "relative", minHeight: "auto" }}
        >
          {tabs.map((tab, index) => (
            <MuiTab
              key={index}
              label={tab.label}
              disableRipple
              value={tab}
              disableFocusRipple
              sx={{
                padding: "5px 20px",
                minHeight: "auto",
                fontSize: "18px",
                lineHeight: "26px",
                fontWeight: 500,
                textTransform: "none",
              }}
              className="text-nowrap"
            />
          ))}
        </MuiTabs>
        {headerSlot && headerSlot}
      </div>

      {tabs.map((tab, index) => (
        <div
          key={index}
          role="tabpanel"
          hidden={tab.label !== selectedTab.label}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
        >
          {tab.label === selectedTab.label && <Box py={3}>{tab.content}</Box>}
        </div>
      ))}
    </Box>
  );
};

export default Tabs;
