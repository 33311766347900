import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "./../../constants";
import { axiosBaseQuery } from "./baseQuery";
import { providesTags } from "./baseQuery";

interface Collector {
  id: number;
  name: string;
  date?: string;
  granularity: number;
  period: number;
  period_type: string;
}

const tag = "Collectors";

export const collectorApi = createApi({
  reducerPath: "collectorApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getCollectors: builder.query<Collector[], void>({
      query: () => ({ url: "/collector/config", method: "GET" }),
      providesTags: providesTags(tag),
    }),
    getCollectorById: builder.query<Collector, number>({
      query: (id) => ({ url: `/collector/config/${id}`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: tag, id }],
    }),
    addCollector: builder.mutation<Collector, Partial<Collector>>({
      query: (data) => ({
        url: "/collector/config",
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    editCollector: builder.mutation<
      Collector,
      { id: number; data: Partial<Collector> }
    >({
      query: ({ id, data }) => ({
        url: `/collector/config/${id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: tag, id }],
    }),
    deleteCollector: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `/collector/config/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
  }),
});

export const {
  useGetCollectorsQuery,
  useGetCollectorByIdQuery,
  useAddCollectorMutation,
  useEditCollectorMutation,
  useDeleteCollectorMutation,
} = collectorApi;
