import { Navigate } from "react-router-dom";

import { useGetAccountQuery } from "../../redux/api/accountApi";

import AdminPanel from "./AdminPanel";

const AdminPanelRoute: React.FC = () => {
  const token = localStorage.getItem("access_token");
  const { data: account } = useGetAccountQuery(undefined, {
    skip: !token,
  });

  if (!account) {
    return <Navigate to="/login" replace />;
  }

  if (account?.username === "botico") {
    return <AdminPanel />;
  }
};

export default AdminPanelRoute;
