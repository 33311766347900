import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Collapse,
  Checkbox,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  PaginationState,
  RowSelectionState,
} from "@tanstack/react-table";

import styles from "./table.module.scss";

type AppTableProps<T> = {
  data: T[];
  columns: ColumnDef<T, any>[];
  collapsedContentComponent?: React.FC<{ rowData: T }>;
  enableRowSelection?: boolean;
  enablePagination?: boolean;
  onRowSelectionChange?: (selectedRows: T[]) => void;
};

function AppTable<T>({
  data,
  columns,
  collapsedContentComponent,
  enableRowSelection = false,
  enablePagination = true,
  onRowSelectionChange,
}: AppTableProps<T>) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const parentRef = useRef(null);

  const tableColumns = useMemo(() => {
    if (!enableRowSelection) return columns;

    return [
      {
        id: "select",
        header: ({ table }) => {
          return (
            <Checkbox
              indeterminate={table.getIsSomePageRowsSelected()}
              checked={table.getIsAllPageRowsSelected()}
              onChange={table.getToggleAllPageRowsSelectedHandler()}
              aria-label="Select all"
            />
          );
        },
        size: 60,
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            aria-label="Select row"
          />
        ),
      },
      ...columns,
    ];
  }, [columns, enableRowSelection]);

  const table = useReactTable({
    data,
    columns: tableColumns,
    pageCount: Math.ceil(data.length / pagination.pageSize),
    state: { pagination, rowSelection },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection,
  });

  const selectedRows = useMemo(
    () => table.getSelectedRowModel().rows.map((row) => row.original),
    [table, rowSelection],
  );

  useEffect(() => {
    if (onRowSelectionChange) {
      onRowSelectionChange(selectedRows);
    }
  }, [selectedRows, onRowSelectionChange]);

  useEffect(() => {
    setRowSelection({});
  }, [data.length]);

  const handleExpandRow = (rowId: number) =>
    setExpandedRows((prev) =>
      prev.includes(rowId)
        ? prev.filter((id) => id !== rowId)
        : [...prev, rowId],
    );

  return (
    <Paper>
      <TableContainer component={Paper} ref={parentRef}>
        <Table className={styles.table}>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {collapsedContentComponent && <TableCell className="w-1" />}
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    style={{ minWidth: header.column.columnDef.size }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <React.Fragment key={row.id}>
                    <TableRow>
                      {collapsedContentComponent && (
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleExpandRow(Number(row.id))}
                          >
                            {expandedRows.includes(Number(row.id)) ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                        </TableCell>
                      )}
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    {collapsedContentComponent && (
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={
                            row.getVisibleCells().length +
                            (enableRowSelection ? 2 : 1)
                          }
                        >
                          <Collapse
                            in={expandedRows.includes(Number(row.id))}
                            timeout="auto"
                            unmountOnExit
                          >
                            <div style={{ padding: "16px" }}>
                              {collapsedContentComponent({
                                rowData: row.original,
                              })}
                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  valign="middle"
                  className="text-center"
                >
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {enablePagination && data.length > 0 && (
        <TablePagination
          className={styles.tablePagination}
          component="div"
          count={data.length}
          page={pagination.pageIndex}
          onPageChange={(_, newPage) =>
            setPagination((prev) => ({ ...prev, pageIndex: newPage }))
          }
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={(event) =>
            setPagination((prev) => ({
              ...prev,
              pageSize: parseInt(event.target.value, 10),
              pageIndex: 0,
            }))
          }
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
    </Paper>
  );
}

export default AppTable;
