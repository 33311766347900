import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "./../../constants";
import { axiosBaseQuery } from "./baseQuery";
import { providesTags } from "./baseQuery";

const tag = "AdminPanel";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getAccountConfirmation: builder.query<any, void>({
      query: () => ({ url: "/admin/accountconfirmation", method: "GET" }),
      providesTags: providesTags(tag),
    }),
    getSwaggerUI: builder.query<any, void>({
      query: () => ({ url: "/admin/swagger-ui", method: "GET" }),
    }),
    confirmAccount: builder.mutation<any, Partial<any>>({
      query: (data) => ({
        url: "/admin/accountconfirmation",
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
  }),
});

export const {
  useGetAccountConfirmationQuery,
  useGetSwaggerUIQuery,
  useConfirmAccountMutation,
} = adminApi;
