import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "./../../constants";
import { axiosBaseQuery } from "./baseQuery";
import { providesTags } from "./baseQuery";

interface IExchange {
  name: string;
  id: number;
}

const tag = "Exchange";

export const exchangeApi = createApi({
  reducerPath: "exchangeApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getExchange: builder.query<IExchange[], void>({
      query: () => ({ url: "/exchange", method: "GET" }),
      providesTags: providesTags(tag),
    }),
    addExchange: builder.mutation<IExchange, Partial<IExchange>>({
      query: (data) => ({
        url: "/exchange",
        method: "POST",
        data: { name: data },
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    removeExchange: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `/exchange/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
  }),
});

export const {
  useGetExchangeQuery,
  useLazyGetExchangeQuery,
  useAddExchangeMutation,
  useRemoveExchangeMutation,
} = exchangeApi;
