import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import BrowserRouter from "./BrowserRouter";
import history from "./history";
import App from "./pages/App/App";
import { store } from "./redux/store";
import "./i18n";

import "./scss/index.scss";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <BrowserRouter history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
);
