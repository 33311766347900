import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ModalComponentTypeEnum, ModalMaxWidthType } from "../../types/types";

type ModalState = {
  isOpen: boolean;
  title: string | null;
  maxWidth: ModalMaxWidthType | undefined;
  componentType: ModalComponentTypeEnum | null;
  componentProps: Record<string, unknown> | null;
};

type OpenModalPayload = {
  title?: string;
  content?: string;
  maxWidth?: ModalMaxWidthType;
  componentType: ModalComponentTypeEnum;
  componentProps: Record<string, unknown> | null;
};

const initialState: ModalState = {
  isOpen: false,
  title: null,
  componentType: null,
  componentProps: null,
  maxWidth: undefined,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      state.isOpen = true;
      state.title = action.payload.title || null;
      state.componentType = action.payload.componentType;
      state.componentProps = action.payload.componentProps || null;
      state.maxWidth = action.payload.maxWidth;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.title = null;
      state.componentType = null;
      state.componentProps = null;
      state.maxWidth = undefined;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
