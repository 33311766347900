import LoginWrapper from "../Login/LoginWrapper";

import styles from "./../Login/login.module.scss";
import RegisterForm from "./RegisterForm";

const Registration: React.FC = () => {
  return (
    <div className={styles.login}>
      <LoginWrapper />
      <div className={styles.loginFormWrapper}>
        <RegisterForm />
      </div>
    </div>
  );
};

export default Registration;
