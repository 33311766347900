import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect } from "react";

import { darkTheme } from "./settings";
import AppRoutes from "./AppRouters";

import Modal from "@/components/Modal/Modal";

const App: React.FC = () => {
  useEffect(() => {
    if (typeof Node === "function" && Node.prototype) {
      const nodeProto = Node.prototype as any;

      const originalRemoveChild = nodeProto.removeChild;
      nodeProto.removeChild = function (child: Node) {
        if (child.parentNode !== this) {
          if (console) {
            console.error(
              "Cannot remove a child from a different parent",
              child,
              this,
            );
          }
          return child;
        }
        return originalRemoveChild.call(this, child);
      };

      const originalInsertBefore = nodeProto.insertBefore;
      nodeProto.insertBefore = function (
        newNode: Node,
        referenceNode: Node | null,
      ) {
        if (referenceNode && referenceNode.parentNode !== this) {
          if (console) {
            console.error(
              "Cannot insert before a reference node from a different parent",
              referenceNode,
              this,
            );
          }
          return newNode;
        }
        return originalInsertBefore.call(this, newNode, referenceNode);
      };
    }
  }, []);

  return (
    <div className="botico-main">
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <ToastContainer limit={5} autoClose={3000} theme="dark" />
          <Modal />
          <AppRoutes />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
};

export default App;
