import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "./../../constants";
import { axiosBaseQuery } from "./baseQuery";
import { providesTags } from "./baseQuery";

interface IBQCurrency {
  name: string;
  id: number;
}

const tag = "Currency";

export const currencyApi = createApi({
  reducerPath: "currencyApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getBQCurrency: builder.query<IBQCurrency[], void>({
      query: () => ({ url: "/coins/bqcurrency", method: "GET" }),
      providesTags: providesTags(tag),
    }),
    addBQCurrency: builder.mutation<IBQCurrency, Partial<IBQCurrency>>({
      query: (data) => ({
        url: "/coins/bqcurrency",
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    removeBQCurrency: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `/coins/bqcurrency/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
  }),
});

export const {
  useGetBQCurrencyQuery,
  useAddBQCurrencyMutation,
  useRemoveBQCurrencyMutation,
} = currencyApi;
