import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import rootReducer from "./rootReducers";
import { authApi } from "./api/authApi";
import { collectorApi } from "./api/collectorApi";
import { statisticsApi } from "./api/statisticsApi";
import { riskConfigApi } from "./api/riskConfigApi";
import { accountApi } from "./api/accountApi";
import { botInstanceApi } from "./api/botInstanceApi";
import { exchangeApi } from "./api/exchangeApi";
import { currencyApi } from "./api/currencyApi";
import { signalLegoApi } from "./api/signalLegoApi";
import { adminApi } from "./api/adminApi";
import { buyOrdersApi } from "./api/buyOrdersApi";
import { sellOrdersApi } from "./api/sellOrderApi";
import { reportApi } from "./api/reportApi";
import { backTestingApi } from "./api/backtestingApi";
import { backTestCOllectorApi } from "./api/backTestCollectorApi";
import { externalApi } from "./api/externalApi";
import { coinsApi } from "./api/coinsApi";
import { openOrdersApi } from "./api/openOrdersApi";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      botInstanceApi.middleware,
      collectorApi.middleware,
      statisticsApi.middleware,
      riskConfigApi.middleware,
      accountApi.middleware,
      exchangeApi.middleware,
      currencyApi.middleware,
      signalLegoApi.middleware,
      adminApi.middleware,
      buyOrdersApi.middleware,
      sellOrdersApi.middleware,
      reportApi.middleware,
      backTestingApi.middleware,
      backTestCOllectorApi.middleware,
      externalApi.middleware,
      coinsApi.middleware,
      openOrdersApi.middleware,
    ),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
