import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "../../constants";

import { axiosBaseQuery } from "./baseQuery";

const tag = "OpenOrders";

export const openOrdersApi = createApi({
  reducerPath: "openOrdersApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getOpenOrders: builder.query<any, void>({
      query: () => ({ url: "/orders/open", method: "GET" }),
      transformResponse: (response: any) => response,
    }),
  }),
});

export const { useGetOpenOrdersQuery } = openOrdersApi;
