import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 20}
    height={props.height || 20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke={props.stroke || "#F9F9FF"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14.167 15.358h-3.334l-3.708 2.467a.83.83 0 0 1-1.292-.692v-1.775c-2.5 0-4.166-1.667-4.166-4.167v-5c0-2.5 1.666-4.166 4.166-4.166h8.334c2.5 0 4.166 1.666 4.166 4.166v5c0 2.5-1.666 4.167-4.166 4.167Z"
    />
    <path
      stroke={props.stroke || "#F9F9FF"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 9.467v-.175c0-.567.35-.867.7-1.108.342-.234.683-.534.683-1.084A1.38 1.38 0 0 0 10 5.717 1.38 1.38 0 0 0 8.617 7.1M9.996 11.458h.008"
    />
  </svg>
);
export default SvgComponent;
