import { Link } from "react-router-dom";

import AppTable from "../../components/Table/AppTable";
import {
  useConfirmAccountMutation,
  useGetAccountConfirmationQuery,
} from "../../redux/api/adminApi";
import DataCollection from "../../components/BotBuilder/DataCollection/DataCollection";
import { useTabs } from "../../hooks/useTabs";
import Tabs from "../../components/Tabs/tabs";

import styles from "./admin.module.scss";
import { getAccountConfirmationColumns } from "./columns/columns";
import BQPair from "./Pairs";
import CurrencyCoins from "./CurrencyCoins";

const AdminPanel: React.FC = () => {
  const { data: confirmationAccounts } = useGetAccountConfirmationQuery();
  const [confirmAccount] = useConfirmAccountMutation();
  const columns = getAccountConfirmationColumns(confirmAccount);

  const tabs = [
    {
      label: "Users",
      content: <AppTable data={confirmationAccounts || []} columns={columns} />,
    },
    {
      label: "Collector",
      content: <DataCollection />,
    },
    {
      label: "Coins",
      content: <CurrencyCoins />,
    },
    {
      label: "Pairs",
      content: <BQPair />,
    },
  ];

  const { selectedTab, handleTabChange } = useTabs({ tabs });

  return (
    <div className={styles.adminPanel}>
      <div className="flex items-center justify-between">
        <div className={styles.title}>Admin Dashboard</div>
        <Link className="text-primary-succes-500" to="/admin/swagger-ui">
          {" "}
          Swagger{" "}
        </Link>
      </div>

      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
    </div>
  );
};

export default AdminPanel;
