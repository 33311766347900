import { useState } from "react";
import Button from "@mui/material/Button";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";

import VirtualizedTable from "../../VirtualizedTable/VirtualizedTable";
import {
  useDeleteCollectorMutation,
  useGetCollectorsQuery,
} from "../../../redux/api/collectorApi";

import { dataCollectorTableColumns } from "./columns";
import DataCollectionModal from "./DataCollectionModal";

import "./styles.scss";

const DataCollection = () => {
  const { data: collectors } = useGetCollectorsQuery();
  const [deleteCollector] = useDeleteCollectorMutation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editId, setEditId] = useState(null);

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setIsEditMode(false);
    setEditId(null);
  };

  const handleDeleteCollector = async (id) => {
    await deleteCollector(id);
  };

  const handleEditConfig = (id) => {
    setEditId(id);
    setIsEditMode(true);
    handleOpenModal();
  };

  return (
    <div className="data-collection">
      <Button
        variant="contained"
        className="bg-neo my-8 p-2 py-1"
        onClick={handleOpenModal}
      >
        <StorageOutlinedIcon className="mr-1" />
        Data Collection Config
      </Button>

      <VirtualizedTable
        data={collectors || []}
        columns={dataCollectorTableColumns(
          handleEditConfig,
          handleDeleteCollector,
          setIsEditMode,
        )}
        size="small"
      />

      {isOpenModal && (
        <DataCollectionModal
          open={isOpenModal}
          handleClose={handleCloseModal}
          isEditMode={isEditMode}
          editId={editId}
        />
      )}
    </div>
  );
};

export default DataCollection;
