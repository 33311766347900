import { createApi } from "@reduxjs/toolkit/query/react";

import { API_URL } from "../../constants";

import { axiosBaseQuery } from "./baseQuery";

interface BuyOrderResponse {
  orders: any;
  info: {
    number_of_orders: number;
    number_of_pages: number;
  };
}

interface FilterParams {
  bot_uuid?: string;
  currency?: string;
  status?: string;
}

export const buyOrdersApi = createApi({
  reducerPath: "buyOrdersApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getBuyOrders: builder.query<
      BuyOrderResponse,
      { pageSize: number; filters?: FilterParams }
    >({
      query: ({ pageSize, filters = {} }) => {
        let queryString = `/orders/buy/${pageSize}`;
        if (filters) {
          const filterQuery = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

          if (filterQuery) {
            queryString +=
              (queryString.includes("?") ? "&" : "?") + filterQuery;
          }
        }
        return { url: queryString, method: "GET" };
      },
    }),
    cancelBuyOrder: builder.mutation<void, string>({
      query: (id) => ({
        url: `/orders/buy/cancel/${id}`,
        method: "PUT",
      }),
    }),
  }),
});

export const { useGetBuyOrdersQuery, useCancelBuyOrderMutation } = buyOrdersApi;
