import { Suspense, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import Header from "../Header/Header";

import Sidebar from "./SideBar";

const Layout: React.FC = () => {
  const [open, setOpen] = useState<boolean>(true);
  const accessToken = localStorage.getItem("access_token");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleScrollChange = (event: WheelEvent) => {
      if (
        document.activeElement instanceof HTMLInputElement &&
        document.activeElement.type === "number"
      ) {
        event.preventDefault();
        document.activeElement.blur();
      }
    };

    document.addEventListener("wheel", handleScrollChange);

    return () => {
      document.removeEventListener("wheel", handleScrollChange);
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {accessToken && <Header open={open} />}
        <div className="layout">
          <div className="main">
            <div className="container">
              <Suspense fallback={<p>Loading...</p>}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Layout;
