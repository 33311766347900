import { Outlet, Navigate, useLocation } from "react-router-dom";

const UserOffline = ({ children }) => {
  const accessToken = localStorage.getItem("access_token");
  const location = useLocation();

  if (accessToken) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children ? children : <Outlet />;
};

export default UserOffline;
