import { TextField, Chip } from "@mui/material";
import { useForm, Controller } from "react-hook-form";

import AppButton from "../../components/Button/AppButton";
import {
  useAddCoinMutation,
  useDeleteCoinMutation,
  useGetCoinsQuery,
} from "../../redux/api/coinsApi";
import Coin from "../../components/Coin/Coin";

const CurrencyCoins: React.FC = () => {
  const { data: coins } = useGetCoinsQuery();
  const [addCoin] = useAddCoinMutation();
  const [deleteCoin] = useDeleteCoinMutation();

  const { control, handleSubmit } = useForm({
    defaultValues: { name: "" },
  });

  const onSubmit = async (value) => {
    await addCoin(value);
  };

  const handleRemove = async (id: number) => {
    await deleteCoin(id);
  };

  console.log(coins);

  return (
    <div className="p-4">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-4 flex items-center space-x-2"
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              variant="outlined"
              className="w-64"
              onChange={(event) => {
                field.onChange(event);
              }}
              autoComplete="off"
            />
          )}
        />
        <AppButton size="small" type="submit" variant="primary">
          Add
        </AppButton>
      </form>

      <div className="mt-8 flex flex-wrap">
        {coins
          ? coins?.map((currency) => (
              <div key={currency.id} className="flex p-2 items-center">
                <Chip
                  key={currency.name}
                  avatar={<Coin width={16} height={16} name={currency?.name} />}
                  label={currency.name.toUpperCase()}
                  onDelete={() => handleRemove(currency.id)}
                  className="bg-transparent"
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default CurrencyCoins;
